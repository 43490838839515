<template>
    <div class="row" v-if="this.data">
        <div class="col-6">
            <table class="table">
                <tr class="table-headers-row">
                    <template v-for="(header, ndx) of headers">
                        <th :class="['table-headers-cell', {'no-display': ndx==='interim_payments' && !showPayments}]" :key="`header-${ndx}-firsttable`">{{header}}</th>
                    </template>
                </tr>
                <tr class="table-data-row" v-for="(dataRow, ndx) in newData()" :key="`data-${ndx}-row-firsttable`">
                    <td :class="['table-data-cell', 'anketa', {'no-display': index==='interim_payments' && !showPayments}]" v-for="(data, index) of normalizeData(dataRow)" :key="`${index}-data-firsttable`">{{index === 'date' ? data : (+data).toLocaleString()}}</td>
                </tr>
            </table>
        </div>
        <div class="col-6 second-table">
            <table class="table" v-if="secondTableData().length > 0">
                <tr class="table-headers-row">
                    <template v-for="(header, ndx) of headers">
                        <th :class="['table-headers-cell', {'no-display': ndx==='interim_payments' && !showPayments}]" :key="`header-${ndx}-firsttable`">{{header}}</th>
                    </template>
                </tr>
                <tr class="table-data-row" v-for="(dataRow, ndx) in secondTableData()" :key="`data-${ndx}-row-firsttable`">
                    <td :class="['table-data-cell', 'anketa', {'no-display': index==='interim_payments' && !showPayments}]" v-for="(data, index) of normalizeData(dataRow)" :key="`${index}-data-firsttable`">{{index === 'date' ? data : (+data).toLocaleString()}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "SchaTable",
        props: {
            headers: Object,
            data: Array,
            showPayments: [Boolean, null]
        },
        data() {
            return {
                windowWidth: 0
            }
        },
        methods: {
            normalizeData(row) {
                let newRow = {}
                for (let headerKey in this.headers) {
                    newRow[headerKey] = headerKey !== "date" ? row[headerKey] : moment(row[headerKey], 'X', true).format('DD.MM.YYYY')
                }
                return newRow
            },
            newData() {
                let newArr = [...this.data]
                return this.windowWidth > 1280 && newArr?.length > 6 ? newArr.splice(0, 6) : this.data
            },
            secondTableData() {
                let newArr = [...this.data]
                return this.windowWidth > 1280 && newArr?.length > 6 ? newArr.splice(6, newArr.length-6) : []
            },
            getWindowWidth() {
                this.windowWidth = window.innerWidth
            }
        },
        mounted() {
            window.addEventListener('resize', this.getWindowWidth);
            this.getWindowWidth()
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.getWindowWidth);
        }
    }
</script>

<style scoped lang="scss">
    .table {
        border-spacing: 12px 12px;
        border-collapse: separate;
        width: 100%;

        &-headers-row {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -12px;
                border-bottom: 2px solid $RWM-grey-light;
            }
        }
        &-headers-cell {
            box-sizing: border-box;
            padding-left: 5px;

            font-size: 18px;
            line-height: 25px;
            color: $RWM-dark;
            &.no-display {
                display: none;
            }
        }
        &-data-row {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -12px;
                border-bottom: 1px solid $RWM-grey-light;
            }
        }
        &-data-cell {
            padding-top: 12px;
            &.no-display {
                display: none;
            }
        }
    }
    .second-table {
        display: none;
        @media screen and (min-width: 1280px) {
            display: block;
        }
    }
</style>